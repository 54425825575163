import type { AvailableFiltersKeys } from '~/composables/filters/types'
import { paths } from '~/constants/paths'
import { IssuesFiltersKeys } from '~/stores/issues/types'

export const FILTERS_TO_BE_INCLUDED_IN_PATH: AvailableFiltersKeys[] = [
  IssuesFiltersKeys.AnnualInterestRate,
  IssuesFiltersKeys.StateBonds,
  IssuesFiltersKeys.CanBeBoughtOnline,
  IssuesFiltersKeys.CollateralTypes,
]

export const BASE_FILTERS_URL = `${paths.corporateIssues}/`
export const FILTERS_DIVIDER = '/'
export const COLLATERAL_SPACE_DIVIDER = '-'
export const FILTER_INITIAL_VALUE = ''

export const MIN_ANNUAL_INTEREST_RATE = 0
export const MAX_ANNUAL_INTEREST_RATE = 20

export const RANGE_FILTER_DIVIDERS = {
  from: 'od-',
  to: 'do-',
}

export const COLLATERALS_DIVIDER = '-a-'
