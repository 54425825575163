import type { BooleanFilters, RangeFilters } from '~/composables/filters/types'
import type { IssuesListFilters } from '~/stores/issues/types'
import { filterKeyToUrlTemplate } from '~/composables/filters/templates'
import { isDefaultValue } from '~/composables/filters/helpers/isDefaultValue'

export function rangeGetter(key: keyof RangeFilters) {
  return function (value: [number, number] | null) {
    if (!value) {
      return null
    }

    const [from, to] = value
    const [isFromDefault, isToDefault] = isDefaultValue(key, value)

    if (isFromDefault && isToDefault) {
      return null
    }

    const result = filterKeyToUrlTemplate[key]
    const fromPart = !isFromDefault ? `od-${from}` : ''
    const toPart = !isToDefault ? `do-${to}` : ''

    return from === to ? `${result}${from}` : `${result}${fromPart}${toPart && fromPart ? '-' : ''}${toPart}`
  }
}

export function booleanGetter<Key extends keyof BooleanFilters>(key: Key) {
  return function (value: IssuesListFilters[Key]) {
    return value ? filterKeyToUrlTemplate[key] : null
  }
}
