import type { AvailableFilters, AvailableFiltersKeys, BooleanFilters, RangeFilters } from '~/composables/filters/types'
import { IssuesFiltersKeys, type IssuesListFilters } from '~/stores/issues/types'
import { issuesFiltersRanges } from '~/stores/issues/constants'
import { IssuesCollateralType } from '~/api/modules/issues/services/getIssuesList/getIssuesList.types'
import { validateFilterValue } from '~/composables/filters/helpers/validateFilterValue'
import { filterKeyToUrlTemplate } from '~/composables/filters/templates'
import { isDefaultValue } from '~/composables/filters/helpers/isDefaultValue'
import {
  COLLATERALS_DIVIDER,
  COLLATERAL_SPACE_DIVIDER,
  FILTERS_DIVIDER,
  RANGE_FILTER_DIVIDERS,
} from '~/composables/filters/constants'

export function parseUrlToFilters(url: string) {
  const tokens = decodeURIComponent(url).split(FILTERS_DIVIDER) as AvailableFiltersKeys[]

  return Object.fromEntries(
    tokens
      .map<[keyof AvailableFilters | null, string | null]>(token => [detectFilterType(token), token])
      .filter(([key, value]) => Boolean(value) && Boolean(key))
      .map(([key, value]) => [key, parseFilterValue(key!, value!)])
      .filter(([_, value]) => value !== null),
  ) as AvailableFilters
}

export function detectFilterType(token: string): keyof AvailableFilters | null {
  if (token.includes(filterKeyToUrlTemplate[IssuesFiltersKeys.AnnualInterestRate])) {
    return IssuesFiltersKeys.AnnualInterestRate
  }

  if (token.includes(filterKeyToUrlTemplate[IssuesFiltersKeys.StateBonds])) {
    return IssuesFiltersKeys.StateBonds
  }

  if (token.includes(filterKeyToUrlTemplate[IssuesFiltersKeys.CanBeBoughtOnline])) {
    return IssuesFiltersKeys.CanBeBoughtOnline
  }

  if (token.includes(filterKeyToUrlTemplate[IssuesFiltersKeys.CollateralTypes])) {
    return IssuesFiltersKeys.CollateralTypes
  }

  return null
}

export function parseFilterValue<Key extends keyof AvailableFilters>(key: Key, value: string): AvailableFilters[Key] | null {
  switch (key) {
    case IssuesFiltersKeys.AnnualInterestRate:
      return parseRangeFilterValue(key, value) as AvailableFilters[Key] | null

    case IssuesFiltersKeys.StateBonds:
    case IssuesFiltersKeys.CanBeBoughtOnline:
      return parseBooleanFilterValue(key, value) as AvailableFilters[Key] | null

    case IssuesFiltersKeys.CollateralTypes:
      return parseCollateralTypesFilterValue(value) as AvailableFilters[Key] | null

    default:
      return null
  }
}

function parseRangeFilterValue<Key extends keyof RangeFilters>(
  key: Key,
  value: string,
): [number, number] | null {
  const { from: fromDivider, to: toDivider } = RANGE_FILTER_DIVIDERS

  const rawFrom = value.match(new RegExp(`${fromDivider}(\\d+)`))?.[0]?.replace(fromDivider, '')
  const rawTo = value.match(new RegExp(`${toDivider}(\\d+)`))?.[0]?.replace(toDivider, '')

  // Dont add filter if values are not valid
  if (!validateFilterValue(key, rawFrom) || !validateFilterValue(key, rawTo)) {
    return null
  }

  const from = Number(rawFrom)
  const to = Number(rawTo)

  const [
    isFromDefault,
    isToDefault,
  ] = isDefaultValue(key as keyof typeof issuesFiltersRanges, [Number(from), Number(to)])

  // Dont add filter if values are default
  if (isFromDefault && isToDefault) {
    return null
  }

  return [
    Number.isNaN(from) ? issuesFiltersRanges[key].min : Number(from),
    Number.isNaN(to) ? issuesFiltersRanges[key].max : Number(to),
  ]
}

function parseCollateralTypesFilterValue(value: string): IssuesCollateralType[] {
  const spaceDividerRegExp = new RegExp(`[${COLLATERAL_SPACE_DIVIDER}]`, 'g')

  // Url decode
  const collaterals = decodeURI(value)
    .replace(filterKeyToUrlTemplate[IssuesFiltersKeys.CollateralTypes], '')
    .split(COLLATERALS_DIVIDER)
    .map(collateral => collateral.replace(spaceDividerRegExp, ' ')) // Replace "-" with spaces to match enum values
    .filter(probablyCollateral => Object.values(IssuesCollateralType).includes(probablyCollateral as IssuesCollateralType))

  return collaterals as IssuesCollateralType[]
}

function parseBooleanFilterValue<Key extends keyof BooleanFilters>(key: Key, value: string): IssuesListFilters[Key] {
  return value === filterKeyToUrlTemplate[key]
}
