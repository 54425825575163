import { pick } from 'lodash-es'
import { filterKeyToUrlTemplate, filterTypeGetters } from './templates'
import type { IssuesListFilters } from '~/stores/issues/types'
import type { AvailableFilters, RangeFilters } from '~/composables/filters/types'
import { BASE_FILTERS_URL, FILTERS_DIVIDER, FILTER_INITIAL_VALUE } from '~/composables/filters/constants'
import { IssuesFiltersKeys } from '~/stores/issues/types'

export function useFiltersUrl(filters: Ref<Partial<IssuesListFilters>>) {
  const url = ref(FILTER_INITIAL_VALUE)

  watch(filters, () => {
    url.value = FILTER_INITIAL_VALUE
    url.value = encodeURI(buildURLFilterPath(filters.value))
  }, { immediate: true, deep: true })

  return { url }
}

const keys = Object.keys(filterKeyToUrlTemplate) as IssuesFiltersKeys[]
export function buildURLFilterPath(filters: Partial<IssuesListFilters>) {
  let url = BASE_FILTERS_URL

  const availableFilters = pick(filters, keys) as AvailableFilters
  const results: Array<string | null> = []

  Object.entries(availableFilters).forEach(([key, value]) => {
    // Rangable filters
    if (key !== IssuesFiltersKeys.CollateralTypes) {
      results.push(filterTypeGetters[key as keyof RangeFilters](value as RangeFilters[keyof RangeFilters]))
    }
  })

  results.push(filterTypeGetters[IssuesFiltersKeys.CollateralTypes](availableFilters.collateralTypes))

  if (results.some(result => result)) {
    url += results.filter(Boolean).join(FILTERS_DIVIDER)
  }

  return url
}
