export const themeColors = {
  brand: {
    default: '#582DC1',
    opacity: '#9768F4',
    light: '#EDE4FF',
    200: '#ECE4FC',
  },

  secondary: {
    default: '#63B4D1',
    light: '#E8F4F8',
  },

  success: {
    default: '#63B4D1',
    light: '#E8F4F8',
    opacity: '#C8E5EF',
    50: '#F0F4EE',
    400: '#81C784',
    500: '#4CAF50',
    700: '#388E3C',
    800: '#2E7D32',
  },

  black: {
    DEFAULT: '#000000',
  },

  yellow: {
    default: '#FEC54C',
    light: '#FFF9ED',
    500: '#FEC54C',
  },

  warning: {
    default: '#FD8058',
    light: '#ffece6',
    opacity: '#FEAE57',
  },

  red: {
    default: '#d16363',
    light: '#f8e8e8',
  },

  grey: {
    100: '#F1F1F5',
    200: '#B2B4C4',
    300: '#8493A8',
    400: '#ff0000',
    500: '#696974',
    stroke: '#DFE5EE',
  },
}

function rgbToHex(r: number, g: number, b: number) {
  return `#${[r, g, b].map((x) => {
    const hex = x.toString(16)
    return hex.length === 1 ? `0${hex}` : hex
  }).join('')}`
}

rgbToHex(248, 232, 232) // ?
