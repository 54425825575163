import qs from 'qs'
import { has, merge, pick } from 'lodash-es'
import { isNumberLike } from '~/helpers/functions'

const keywords = {
  true: true,
  false: false,
  null: null,
  undefined,
}

export function stringifyQuery(query: Record<string, unknown>): string {
  return qs.stringify(query, {
    arrayFormat: 'comma',
    skipNulls: true,
    allowDots: true,
    addQueryPrefix: true,
  })
}

export function transformFullPathToQueryObject<T extends object>(
  fullPath: string,
  initialData?: T,
): Partial<T> {
  const query = fullPath.split('#')[0].split('?')[1]

  if (!query) {
    return {} satisfies Partial<T>
  }

  let resultQuery = qs.parse(decodeURIComponent(query), {
    parseArrays: true,
    ignoreQueryPrefix: true,
    allowDots: true,
    comma: true,
    decoder(value) {
      if (!value.startsWith('0') && isNumberLike(value)) {
        return Number.parseFloat(value)
      }

      if (has(keywords, value)) {
        return keywords[value as keyof typeof keywords]
      }

      // czech language fix
      return value.replace(/\+/g, ' ')
    },
  })

  if (initialData) {
    resultQuery = pick(resultQuery, Object.keys(initialData))
  }

  resultQuery = Object.fromEntries(
    Object
      .entries(resultQuery)
      .map(([key, value]) => {
        let resultValue = value

        // Single value to array if initial data is array
        // For example businessTypeByCzNace: 'A' -> businessTypes: ['A']
        if (
          initialData
          && Array.isArray(initialData[key as keyof T]) // This key in initial data is an array
          && !Array.isArray(value) // This value is not array
        ) {
          resultValue = [value] // Convert to an array to consistent data structure
        }

        // Parse numbers in arrays
        if (Array.isArray(value)) {
          resultValue = value.map((item) => {
            return isNumberLike(item) && typeof item === 'string' ? Number.parseFloat(item) : item
          })
        }

        // In some cases search string contains characters that we parse as an array.
        // For example, "PORTIVA Automotive Finance s.r.o., 9,00%, 2024 - 2029". will be parsed as ["PORTIVA Automotive Finance s.r.o.", "9", "00%", "2024 - 2029"]
        if (key === 'search' && Array.isArray(resultValue)) {
          resultValue = resultValue.join(' ')
        }

        return [key, resultValue]
      }),
  )

  return merge(initialData, resultQuery) satisfies Partial<T>
}
