import type { AvailableFilters } from '~/composables/filters/types'
import { issuesFiltersRanges } from '~/stores/issues/constants'

export function validateFilterValue(key: keyof AvailableFilters, value?: string) {
  if (value === null || value === undefined) {
    return true
  }

  const {
    min = Number.MIN_SAFE_INTEGER,
    max = Number.MAX_SAFE_INTEGER,
  } = issuesFiltersRanges[key as keyof typeof issuesFiltersRanges] || {}

  const numberValue = Number(value)

  return !Number.isNaN(numberValue) && numberValue >= min && numberValue <= max
}
