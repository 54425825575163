import { cloneDeep } from 'lodash-es'
import { IssueSortKeys } from '~/api/modules/issues/services/getIssuesList/getIssuesList.types'
import { SortDirection } from '~/api/types'
import type { IssueSort, IssuesListFilters, IssuesListStore, IssuesStoreSort } from '~/stores/issues/types'
import { IssuesFiltersKeys } from '~/stores/issues/types'

export const SHOW_ISSUES_PER_PAGE = 10
export const MAX_HIGHLIGHTED_ISSUES = 4
export const ARCHIVED_ISSUES = 10
export const BANNER_POSITION = 3

export const defaultSort: IssueSort = {
  direction: SortDirection.ASC,
  active: false,
}

export const issuesFiltersRanges = {
  [IssuesFiltersKeys.AnnualInterestRate]: {
    min: 0,
    max: 20,
  },
  [IssuesFiltersKeys.MfcrScore]: {
    min: 0,
    max: 19,
  },
  [IssuesFiltersKeys.IssueMaturity]: {
    min: 0,
    max: 10,
  },
}

export const filtersInitialState: Partial<IssuesListFilters> = {
  [IssuesFiltersKeys.AnnualInterestRate]: null,
  [IssuesFiltersKeys.MfcrScore]: null,
  [IssuesFiltersKeys.IssueMaturity]: null,
  [IssuesFiltersKeys.CollateralTypes]: [],
  [IssuesFiltersKeys.HasEarlyRepayment]: false,
  [IssuesFiltersKeys.IsProspectusApprovedByCNB]: false,
  [IssuesFiltersKeys.StateBonds]: false,
  [IssuesFiltersKeys.OnlyOur]: false,
  [IssuesFiltersKeys.CanBeBoughtOnline]: false,
  [IssuesFiltersKeys.DmatChecks]: 0,
}

export const initialPagination = {
  start: 0,
  limit: SHOW_ISSUES_PER_PAGE,
}

export const initialTotalCountForList = { current: 0, max: 0 }

export const initialSort: IssuesStoreSort = {
  [IssueSortKeys.dmatChecks]: cloneDeep(defaultSort),
  [IssueSortKeys.annualInterestRate]: cloneDeep(defaultSort),
  [IssueSortKeys.mfCrScore]: cloneDeep(defaultSort),
}

export const initialList = {
  issues: [],
  pagination: cloneDeep(initialPagination),
  totalCount: cloneDeep(initialTotalCountForList),
  loading: 0,
  pending: 0,
  initialCount: 0,
} satisfies IssuesListStore
