import { booleanGetter, rangeGetter } from './helpers/filterTypesGetters'
import type { AvailableFiltersKeys } from '~/composables/filters/types'
import type { IssuesCollateralType } from '~/api/modules/issues/services/getIssuesList/getIssuesList.types'
import { IssuesFiltersKeys } from '~/stores/issues/types'
import { COLLATERAL_SPACE_DIVIDER } from '~/composables/filters/constants'

export const filterKeyToUrlTemplate = {
  [IssuesFiltersKeys.AnnualInterestRate]: 's-urokem-',
  [IssuesFiltersKeys.StateBonds]: 'statni-dluhopisy',
  [IssuesFiltersKeys.CanBeBoughtOnline]: 'zakoupit-online-u-emitenta',
  [IssuesFiltersKeys.CollateralTypes]: 'zajisteni-',
} satisfies Record<AvailableFiltersKeys, string>

export const filterTypeGetters = {
  [IssuesFiltersKeys.AnnualInterestRate]: rangeGetter(IssuesFiltersKeys.AnnualInterestRate),

  [IssuesFiltersKeys.StateBonds]: booleanGetter(IssuesFiltersKeys.StateBonds),

  [IssuesFiltersKeys.CanBeBoughtOnline]: booleanGetter(IssuesFiltersKeys.CanBeBoughtOnline),

  [IssuesFiltersKeys.CollateralTypes](value?: IssuesCollateralType[]) {
    // Replace spaces in collateral types with a divider (-)
    const normalizedCollaterals = value?.map(collateral => collateral.replace(/ /g, COLLATERAL_SPACE_DIVIDER))
    return normalizedCollaterals?.length
      ? `${filterKeyToUrlTemplate[IssuesFiltersKeys.CollateralTypes]}${normalizedCollaterals.join('-a-')}`
      : null
  },
}
