import { captureException } from '@sentry/core'
import type { Strapi4RequestParams, Strapi4Response } from '@nuxtjs/strapi'
import type { IssueCommercialBanner } from '@api-types/components/IssueCommercialBanner'
import { simplifyStrapiResponse } from '~/api/helpers/functions'
import type { ShortIssueWithId } from '~/api/modules/issues/services/getIssuesList/getIssuesList.types'
import { apiRequest } from '~/api/helpers/apiRequest'

export type ShortIssueWithIdWithBanner = ShortIssueWithId & { commercialBanner: IssueCommercialBanner }

export async function getBanner(): Promise<IssueCommercialBanner | null> {
  const params: Strapi4RequestParams = {
    fields: ['id'],
    populate: ['commercialBanner', 'commercialBanner.desktopBanner', 'commercialBanner.mobileBanner'],
    filters: {
      commercialBanner: {
        link: { $notNull: true },
      },
    },
  }

  try {
    const response = await apiRequest<Strapi4Response<ShortIssueWithIdWithBanner>>('/issues/randomRow/', params, {
      method: 'GET',
    })
    return response ? simplifyStrapiResponse<ShortIssueWithIdWithBanner>(response).commercialBanner : null
  }
  catch (e) {
    // TODO: Specify error type
    if ((e as any).error?.status !== 404) {
      captureException(e)
    }
    return null
  }
}
